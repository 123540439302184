import React from 'react';
import { Link } from 'react-router-dom';
import './NavigationBox.less';

const NavigationBox = ({ to, title }) => (
  
    <Link to={to} className="navigation-link">
      <div className="navigation-box">
      {title}
      </div>
    </Link>
);

export default NavigationBox;
