import React, { useState } from 'react';
import axios from 'axios';
import InputMask from 'react-input-mask';
import Loader from '../Loader/loader';
import './CepLookup.less';

const CepLookup = () => {
  const [cep, setCep] = useState('');
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setCep(e.target.value);
  };

  const handleButtonClick = async () => {
    setError(null);
    setLoading(true);
    const cepWithoutHyphen = cep.replace('-', '');
    try {
      const response = await axios.get(`https://api.svcs.biz/v1/location/${cepWithoutHyphen}`);
      setTimeout(() => {
        setLocation(response.data);
        setLoading(false);
      }, 500); // Ajustar o delay para 500 milissegundos
    } catch (err) {
      setError('Erro ao buscar o CEP');
      setLocation(null);
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleButtonClick();
    }
  };

  const locationLabels = {
    postcode: 'CEP',
    street: 'Rua',
    ibge_code: 'Código IBGE',
    lat: 'Latitude',
    long: 'Longitude',
    neighborhood: 'Bairro',
    region_code: 'Estado',
    city: 'Cidade',
  };

  return (
    <div className="cep-lookup">
      <h1>Consulta de endereço</h1>
      <div>
        <InputMask
          mask="99999-999"
          value={cep}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown} // Adiciona o manipulador de eventos onKeyDown
        >
          {(inputProps) => <input {...inputProps} placeholder="Digite o CEP" />}
        </InputMask>
        <button onClick={handleButtonClick} className="btn-consultar">
          Consultar CEP
        </button>
      </div>
      {error && <p>{error}</p>}
      {loading ? (
        <Loader />
      ) : (
        location && (
          <div className="result">
            <h3>Resultado:</h3>
            <ul>
              {Object.keys(location).map((key) => (
                <li key={key}>
                  <strong>{locationLabels[key]}:</strong> {location[key] || 'N/A'}
                </li>
              ))}
            </ul>
          </div>
        )
      )}
    </div>
  );
};

export default CepLookup;
