import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import CepLookup from './components/CepLookup/CepLookup';
import NavigationBox from './components/NavigationBox/NavigationBox';
import './App.less';
import logo from './assets/logo.svg';

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="header-content">
            <div className="menu-icon" onClick={toggleDrawer}>
              &#9776;
            </div>
            <Link to="/"> {/* Adicione este Link */}
              <img src={logo} className="logo" alt="Logo" />
            </Link>
          </div>
          <nav className={`drawer ${drawerOpen ? 'open' : ''}`}>
            <ul>
              <li onClick={toggleDrawer}>
                <Link to="/consult-address">Consulta CEP</Link>
              </li>
              
            </ul>
          </nav>
        </header>
        <main>
          <Routes>
            <Route
              path="/"
              element={
                <div className="home">
                  <NavigationBox to="/consult-address" title="Consulta CEP" />
                </div>
              }
            />
            <Route path="/consult-address" element={<CepLookup />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
